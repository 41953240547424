import React, { useEffect, useState,useRef } from 'react';
import './App.css';
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation }  from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareAlt,faShare, faShareSquare } from '@fortawesome/free-solid-svg-icons'

import { css } from "@emotion/react";
import { DotLoader	} from "react-spinners";
import LoadingOverlay from 'react-loading-overlay-ts';
//import bootstrap from 'bootstrap'
import Employee from './page/Employee'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PwaInstallPopupIOS from 'react-pwa-install-ios';








let deferredPrompt;  
    
var QRCode = require('qrcode.react');
const baseURL = 'https://pwa.tcit.ae/employee/';


const override = css`
 display:block;
  margin: 0 auto;
  border-color: red;
`;


function App() {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#fff");
  const [isActive, setActive] = useState(false);
  const imgRef = useRef(null);


  const [installable, setInstallable] = useState(true);
  const getQueryParams = () => window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {});
  const repo =`${baseURL}${getQueryParams().cid}/${getQueryParams().eid}` ;
  const logo ={};

  const [apiData, setApiData] = useState({});
  const [empData, setEmpData] = useState({});
  const [apiDataValidate, setApiDataValidate] = useState(true);


const manifest= {
  "short_name": "Vcard",
  "name": "Vcard",
  "icons": [
    {
      "src": "favicon.ico",
      "sizes": "64x64 32x32 24x24 16x16",
      "type": "image/x-icon"
    },
    {
      "src": "small.png",
      "type": "image/png",
      "sizes": "192x192"
    },
    {
      "src": "medium.png",
      "type": "image/png",
      "sizes": "512x512"
    }
  ],
 
  "start_url": `${window.location.href}`,
  "display": "standalone",
  "theme_color": "#FFFFFF",
  "background_color": "#ffffff"
}


// const link = document.createElement("link");
//   link.rel = "manifest";    
//   const stringManifest = JSON.stringify({ ...manifest,
//    start_url: window.location.href});
//   link.setAttribute('href', 'data:application/json;charset=utf-8,' + encodeURIComponent(stringManifest))
//   document.head.appendChild(link);

  // const manifestElement = document.getElementById("manifest");
  // const manifestString = JSON.stringify({
  //   ...manifest,
  //   start_url: window.location.href,
  // });

  // manifestElement.setAttribute(
  //   "href",
  //   "data:application/json;charset=utf-8," + encodeURIComponent(manifestString)
  // );


//   const manifestElement = document.getElementById("manifest");
// const manifestString = JSON.stringify({
//   ...manifest,
//   start_url: window.location.href,
// });
// manifestElement?.setAttribute(
//   "href",
//   "data:application/json;charset=utf-8," + encodeURIComponent(manifestString)
// );




  const shareLink = async () => {
    try {
      await navigator.share({ title: `${empData.ename}`, url: repo });
      console.log("Data was shared successfully");
      console.log(repo,apiData.name );
    } catch (err) {
      console.error("Share failed:", err.message);
    }
  };

  function onLoad() {
    if (imgRef.current?.complete) {
      setActive(false)
     }
   
    console.log("Loading Image")
  }


  useEffect(() => {
    
     console.log("Benny");



     const urlValue=getQueryParams();
     
  //    console.log("repo",repo);
  //    axios.get(`https://60fd579a1fa9e90017c70df9.mockapi.io/vcards/${urlValue.cid}`).
  //    then(res =>{
  //   setApiData(res.data)
    
  //     //const logo = res.data.logo ;
  //     console.log("repo",getQueryParams());
  //   })

  //   axios.get(`https://60fd579a1fa9e90017c70df9.mockapi.io/vcards/${urlValue.cid}/employee/${urlValue.eid}`).
  //   then(res =>{
  //     setEmpData(res.data)
  //     setActive(true)
  //    //const logo = res.data.logo ;
  //    console.log("api data",apiData);
  //  })
console.log("urlvalue",urlValue.cid,urlValue.eid);

const headers={

  'Access-Control-Allow-Origin' : '*',
  'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  }

  if(urlValue.cid&&urlValue.eid){
   axios.get(`https://148.72.206.209:450/api/Employee/GetVcard?CompanyId=${urlValue.cid}&EmpId=${urlValue.eid}`, {
    
    mode: 'no-cors'
  }
  ).
   then(res =>{


if(res.data.length>0)
{
  setApiDataValidate(true);
  setActive(true)

  res.data.map((empdata)=>{
// if(empdata.EmpPrefLang===empdata.EmpLang)
if(empdata.EmpLang==="en")
setEmpData(empdata)
console.log("API individual data data",empdata);
  })
  console.log("API SWAGGER data",res.data);
} 
else{
  setApiDataValidate(false);
  setEmpData({})
  console.log("API SWAGGER",res.data);
}


     
    //  if(res.data[0])
    //  {
    //  setApiDataValidate(true);

    //  setEmpData(res.data[0])

    //  setActive(true)
    //  console.log("API SWAGGER empty",res.data);
    //  }
    //  else
    //  {
    //  setApiDataValidate(false);
    //  setEmpData({})
    //  console.log("API SWAGGER",res.data);
    //  }
    //const logo = res.data.logo ;
   
  })


  }
  

    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      deferredPrompt = e;
      // Update UI notify the user they can install the PWA
      setInstallable(true);
    });





    window.addEventListener('appinstalled', () => {
      // Log install to analytics
      console.log('INSTALL: Success');
    });
  }, []);




  const handleInstallClick = (e) => {
      // Hide the app provided install promotion
      setInstallable(false);
      // Show the install prompt

      if (deferredPrompt) {

        deferredPrompt.prompt();
      
     
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        deferredPrompt = null;
      });

  
}


  };
  
  return (

  
    
    <LoadingOverlay
    active={isActive}
    spinner
    // text='Loading your content...'
    spinner={<DotLoader 	color={color} loading={loading} css={override} size={100} margin={2}/>}
    styles={{
      overlay: (base) => ({
        ...base,
        background: 'rgba(0, 0, 0, 1)'
      })
    }}
  >

    <div className="App ">

 
    <HelmetProvider >
    <Helmet>
    <link rel="manifest" href={"/manifest.php?link="+encodeURIComponent(window.location.href)}  id="manifest" />
      </Helmet>
      </HelmetProvider>
      {/* <DotLoader 	color={color} loading={loading} css={override} size={100} margin={2}/> */}
    


      <PwaInstallPopupIOS 
      delay={3}
      lang="en"
      appIcon="https://pwa.tcit.ae/logo/tcit.png">
    </PwaInstallPopupIOS>


    <div style={{float:"right",margin:"10px"}} >
          { /*installable &&
            <Button variant="outline-primary float-btn" size="sm" onClick={handleInstallClick}>
              Add to Home Screen
            </Button>

            
            
          */}

<a href="#" onClick={handleInstallClick} className="float">
<span className="icon-home"><svg version="1.1" id="Capa_1" 
	 viewBox="0 0 512 512">
<g>
	<g>
		<path d="M492,236H276V20c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v216H20c-11.046,0-20,8.954-20,20s8.954,20,20,20h216
			v216c0,11.046,8.954,20,20,20s20-8.954,20-20V276h216c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z"/>
	</g>
</g></svg></span>
</a>
<div className="label-container">
<div className="label-text">Add to Home Screen</div>
<i className="fa fa-play label-arrow"></i>
</div>
        </div>

       

         
         
         
  { apiDataValidate ? (  
    
    <div class="outer-wrap">
    <div class="container-fluid p-20">

        <div class="col-lg-12">

            <div class="row mobile-row">
                <div class="custom_width">


                  
                          
                        { getQueryParams().cid ?  ( <> 



                          <div class="card bg-white blue-card-400">
                      
                      
                       
                      <div class="text-center scan">
                             <h3>Scan my QR code</h3>
                             </div>
                        
                        <div class="card-white-custom">



                            <div class="name-qr">
                            <div class="d-block ">

                              
                             <div class="profile-section">
                           
                                <div style={{backgroundImage: `url(${empData.ProfilePic})`}} className="round-img small-pp"></div>
                               
                             </div>
                          <div class="name-part">
                          {empData.Name && <h1 >{empData.Name}</h1>}
                          {empData.Designation && <h3 >{empData.Designation} </h3>}
                                
                               </div>
                                
                                <div class="c-qr-block">
                                
                                <QRCode
                              value={repo}
                              size={180}
                              bgColor={"#ffffff"}
                              level={"L"}
                              includeMargin={false}
                          
                            />
                                </div>
                        </div>
                                 <div class="p-f-20">
                            <div class="logo-place custom-logo ">
                            {
                            
                            empData.Logo  ? 
                            
                            
                            <p className="logo-place custom-logo "><img ref={imgRef} onLoad={onLoad} src={`https://pwa.tcit.ae/logo/${empData.Logo}?_=${(new Date().getTime())}`}   alt="Logo"  decoding="async"></img></p>
                            : <p className="logo-place custom-logo "><img onLoad={onLoad} src="https://pwa.tcit.ae/logo/tcit.png"  alt="Logo" ></img></p>
                                                
                                               } 
                                {/* <img src="https://technocit.com/assets/images/logo/logo-black.png"/> */}
                                </div>

                            <div class="cta-button">
                                <button onClick={shareLink} class="btn btn-primary" id="contact1">Share  <span class="svg-icon-btn">
                                    <svg id="Capa_1" enable-background="new 0 0 551.13 551.13" height="512" viewBox="0 0 551.13 551.13" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m465.016 172.228h-51.668v34.446h34.446v310.011h-344.457v-310.011h34.446v-34.446h-51.669c-9.52 0-17.223 7.703-17.223 17.223v344.456c0 9.52 7.703 17.223 17.223 17.223h378.902c9.52 0 17.223-7.703 17.223-17.223v-344.456c0-9.52-7.703-17.223-17.223-17.223z"/><path d="m258.342 65.931v244.08h34.446v-244.08l73.937 73.937 24.354-24.354-115.514-115.514-115.514 115.514 24.354 24.354z"/></svg></span></button>
                            </div>

                           
                        </div>
                            </div>
                            </div>
                   
                    </div>
                            </>
   ) :

       (    
         
        <div class="card  red-card-400" >
                      
                      
                       
        <div class="text-center scan" >
               <h3>Kindly Check Your URL</h3>
               </div>
          
          <div class="card-white-custom">


 <div className="introPara msg_home">
   <span>Note: Url should have a Company Id and Employee Id 
    <br></br>eg: ?cid=1&amp;eid=1</span>
    <img  className="home-img" src="../assets/img/paper.svg" />
   </div> 
   
   </div>
                   
   </div>
   
   )
   
   
       }
    






                </div>


            </div>



        </div>
    </div>

</div>
    
    
    
   ) : (    
   
   
    <div class="outer-wrap">
    <div class="container-fluid p-20">

        <div class="col-lg-12">

            <div class="row mobile-row">
                <div class="custom_width">

   
   <div class="card  red-card-400" >
                      
                      
                       
   <div class="text-center scan" >
          <h3>Kindly Check Your URL</h3>
          </div>
     
     <div class="card-white-custom">


<div className="introPara msg_home">
<span>Note: Url should have a Company Id and Employee Id 
<br></br>eg: ?cid=1&amp;eid=1</span>
<img  className="home-img" src="../assets/img/paper.svg" />
</div> 

</div>
              
</div>
</div> 

</div>
              
</div>
</div> 

</div>
              

) 










    }


    
 
    </div>

    </LoadingOverlay>

  );
}

export default App;
