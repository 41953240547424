import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Switch, Route,withRouter} from "react-router-dom";
import Employee from './page/Employee'
ReactDOM.render(
  // <React.StrictMode>
    <Router><Switch>
        <Route exact path="/" component={App} />
        <Route path="/employee/:cid/:eid" component={Employee} />
       
     </Switch>
    </Router>,
 
  document.getElementById('root')
);
 {/* </React.StrictMode>, */}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
