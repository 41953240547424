import React, {useEffect, useState,useRef} from 'react'
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import TextError from './TextError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareAlt,faShare,faArrowCircleDown,faAddressCard} from '@fortawesome/free-solid-svg-icons'
import { fab, faWhatsapp } from '@fortawesome/free-brands-svg-icons'
 
var vCardsJS = require('vcards-js');
const FileSaver = require('file-saver');



function Employee() {

    const getQueryParams = () => window.location.search.replace('?', '').split('&').reduce((r,e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {});
    const [apiData, setApiData] = useState({});
    const  [defaultapidata,setdefaultapidata]=useState({});
    const { cid,eid } = useParams()
const [content,setContent]=useState({});
const [lang,setLang]=useState("")   

const [singleLangChecker,setsingleLangChecker]=useState(false)  

//langswitch true=arabic false=englsih
const [langswitch,setLangSwitch]=useState(true)  



var vCard = vCardsJS();
   //const blob=new Blob({});



   const langSetting = (language) =>
   {
if(language==='ar')
{
    setLang("English")
    setContent({head:"يمكنك الوصول إلي على المقابض أدناه:",download:"Vcard تنزيل بتنسيق ",send:"إرسال جهات الاتصال الخاصة بي",submit:"إرسال"} )
}

if(language==='en')
{
    setContent({head:"You can reach me on below handles:",download:"Download as Vcard",send:"SEND MY CONTACT",submit:"SUBMIT"} )
        
    setLang("عربي")
}

   }

   const langSwitchfn =() =>{

    setLangSwitch(!langswitch)

//     console.log(lang,"lang swithc function")
//        if (lang==="عربي")
//        {
//            setLang("English")
// setLangSwitch(false)
//         }

//         if (lang==="English")
//         {

//             setLang("عربي")
//             setLangSwitch(true)

//         }
   }
    useEffect(() => {
    
        
        console.log("use params",cid,eid );
        const urlValue=getQueryParams();
        console.log(urlValue.cid);
        console.log("repo",getQueryParams().cid);

     

const headers={

  'Access-Control-Allow-Origin' : '*',
  'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
  }

 
   axios.get(`https://148.72.206.209:450/api/Employee/GetVcard?CompanyId=${cid}&EmpId=${eid}`, {
    
    mode: 'no-cors'
  }
  ).
   then(res =>{
     
    //  if(res && langswitch)
    //  {
   
    //  setApiData(res.data[0] )

    //  setContent({head:"You can reach me on below handles:",download:"Download as Vcard",send:"SEND MY CONTACT",submit:"SUBMIT"} )
        

    //  setdefaultapidata(res.data[0])
     
    //  console.log("API SWAGGER empty",res.data);
    //  }

    //  else 
     
    //  if(res && !langswitch)
    //  {
    //     setApiData(res.data[1] ) 
    //     setContent({head:"يمكنك الوصول إلي على المقابض أدناه:",download:"Vcard تنزيل بتنسيق ",send:"إرسال جهات الاتصال الخاصة بي",submit:"إرسال"} )
    //  }
    if(res.data.length===1)
    {
        setsingleLangChecker(true)
    }
    if(res.data.length>0)
    {
    //   setApiDataValidate(true);
    //   setActive(true)
    
      res.data.map((empdata)=>{
          if(empdata.EmpLang==="en")
          {
              setdefaultapidata(empdata)
          }

        if(langswitch)
        {
    if(empdata.EmpPrefLang===empdata.EmpLang)
    {
    langSetting(empdata.EmpLang)
    setApiData(empdata)
    }
        }
        else{
            if(empdata.EmpPrefLang!==empdata.EmpLang)
            {
    langSetting(empdata.EmpLang)
    setApiData(empdata)
            }
        }
    console.log("API individual data data",empdata);
      })
      console.log("API SWAGGER data",res.data);
    } 
    else{
      
        setApiData({})
      console.log("API SWAGGER",res.data);
    }
   
  })





   

     }, [langswitch]);


const vcfDownload = () =>
{
    
    vCard.firstName = apiData.Name;
    vCard.organization = apiData.CompanyName;
    vCard.workPhone = apiData.MobileNo;
    vCard.title = apiData.Designation;
    vCard.url = window.location.href;
    const blob = new Blob([ vCard.getFormattedString() ], {type: "text/vcard;charset=utf-8"});
    FileSaver.saveAs(blob, `${apiData.Name}.vcf`);
}



     const [show, setShow] = useState(false);
     const [mailmsg, setMailMsg] = useState('');

const [first,setFirst]=useState("block")
const [second,setSecond]=useState("none")


     const handleClose = () => {setSecond("none");
       setFirst("block");}
     const handleShow = () => {
       
       setFirst("none");
       setSecond("block");

     }
     const form = useRef(null)
     const initialValues =  {
        name: '',
        email: '',
        phone_number:''
    }

    const onsubmit =  () =>
     {
      // event.preventDefault();
      // let myForm = document.getElementsByClassName('custom_form');
       const data = new FormData(form.current);
       const body={
           name:data.get('name'),
           email:data.get('email'),
           mobile:data.get('phone_number'),
            to:apiData.EmailID
       }
       
       //axios.post("http://localhost:8080/vcardmail/contact.php",body).
       axios.post("https://pwa.tcit.ae/contact.php",body).
       then(res =>{
      if(res.data.type==="success")
      {
        setMailMsg(res.data.type);
      }
      
      //setActive(true)
        //const logo = res.data.logo ;
        console.log("res",res.data.type);
      })
       
       //alert(JSON.stringify(body));
    }

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

    const validationSchema = yup.object({
        name: yup.string().required('Required'),
        email: yup.string().email('Invalid email format').required('Required'),
       
        phone_number: yup.string()
  .required("Required")
  .matches(phoneRegExp, 'Phone number is not valid')
  .min(10, "too short")
  .max(10, "too long")
    });
    return (
       
<div class={"outer-wrap " + apiData.EmpLang}>
<Modal show={false}>
  <Modal.Header closeButton onClick={handleClose}>
    <Modal.Title>Send your contact to {apiData.Name}
    <span className="modalPara">Email to {apiData.EmailID}</span>
    </Modal.Title>
   
   
  </Modal.Header>

  <Modal.Body>
  { mailmsg !== "success" ? ( <Formik 
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { validate }) => {
               onsubmit();
            }}
            >
          <Form className="custom_form" ref={form} >
         
                <div className="form-control share_contact">
                <label htmlFor="name">Name</label>
                <Field 
                    type="text" 
                    id="text" 
                    name="name" 
                />
                <ErrorMessage name="name" component={TextError}/>
                </div>
                <div className="form-control share_contact">
                <label htmlFor="email">Email</label>
                <Field 
                    type="email" 
                    id="email" 
                    name="email" 
                />
                <ErrorMessage name="email" component={TextError}/>
                </div>
                <div className="form-control share_contact">
                <label htmlFor="phone_number">Mobile</label>
                <Field 
                    type="text" 
                    id="text" 
                    name="phone_number" 
                />
                  <ErrorMessage name="phone_number" component={TextError}/>
                </div>
              
                {/* <div className="form-control share_contact">
                <label htmlFor="mobile">Mobile</label>
                <Field 
                    type="text"
                    id="text" 
                    name="comments" 
                />
                <ErrorMessage name="comments" component={TextError}/>
                </div> */}
             {/* <button type="submit">Submit</button> */}
          
             <Modal.Footer>
  <Button variant="secondary" onClick={handleClose} >Close</Button>
            <Button variant="primary" 
   
   type="submit"
    >Send</Button>
  </Modal.Footer>
            </Form>
        </Formik>) : ( <div><h4>Mail Sent successfully</h4>
        <Button variant="secondary" onClick={handleClose} >Close</Button> </div>)}
  </Modal.Body>
  
 
</Modal>




        <div class="container">

            <div class="col-12">

                <div class="row mobile-row">
                    <div class="custom_width ">
                        <div class="card bg-white custom-card rounded-edge">
                            {/* <div class="color-design-block">

                            { apiData.ProfilePic    &&   <div class="round-img" style={{backgroundImage: `url(${apiData.ProfilePic})`}}>
                                 
                                </div>
}
                            </div> */}
                         { !singleLangChecker &&  <div  className="language-switch">
                                <span onClick={langSwitchfn}>{lang}</span>
                            </div>
}

<div class="d-flex color-design-block">
                                 <div class="profile-section">
                                <div class="profile-photo">
                               
                                    <div class="sonar-wave"></div>
                                    { apiData.ProfilePic    &&   <div div class="round-img custom-pp" style={{backgroundImage: `url(${apiData.ProfilePic})`}} > </div>}
                             
                                    {/* <div class="round-img custom-pp"></div> */}
                                    </div>
                                     
                                 </div>
                              <div class="name-section">
                                <div class="name-fields text-white">
                                <h1> {apiData.Name ? apiData.Name : ""}</h1>
                                     <h3>{apiData.Designation}</h3>
                            <h3> {apiData.CompanyName}</h3>
                                
                                </div>
                                  </div>
                            </div>
                          
                          
                            <div class="wave-container">
                                <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
                                    <defs>
                                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                                    </defs>
                                    <g class="parallax">
                                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                                        <use xlinkHref="#gentle-wave" x="48" y="0.5" fill="rgba(255,255,255,0.5" />
                                        <use xlinkHref="#gentle-wave" x="48" y="1" fill="rgba(255,255,255,0.3" />
                                        <use xlinkHref="#gentle-wave" x="48" y="2" fill="rgba(255,255,255)" />
                                        
                                    </g>
                                </svg>
                            </div>


                            <div class="p-f-20">
                            <div class="logo-place custom-logo">
                                <img src="https://technocit.com/assets/images/logo/logo-black.png" />
                                </div>
                               
                                <div class="content-section-onload first" style={{display:first}}>
                                   
                                   
                                {(apiData.Whatsapp ||apiData.Linkedin ||apiData.Skype || apiData.Instagram)    &&                    <div class="content-text text-center">
                                        <p>{content.head}</p>
                                    </div>
} 
                                    <div class="social-media">
                                    {apiData.MobileNo && 
                                       <a href={`tel:${defaultapidata.MobileNo}`}>
                                           <div class="s_icon">
                                         
<svg version="1.1" id="Layer_1" className="mobile-color"
	 viewBox="0 0 512 512" style={{enableBackground:"new 0 0 512 512"}}>
<g>
	<g>
		<path d="M436.992,74.953c-99.989-99.959-262.08-99.935-362.039,0.055s-99.935,262.08,0.055,362.039s262.08,99.935,362.039-0.055
			c48.006-48.021,74.968-113.146,74.953-181.047C511.986,188.055,485.005,122.951,436.992,74.953z M387.703,356.605
			c-0.011,0.011-0.022,0.023-0.034,0.034v-0.085l-12.971,12.885c-16.775,16.987-41.206,23.976-64.427,18.432
			c-23.395-6.262-45.635-16.23-65.877-29.525c-18.806-12.019-36.234-26.069-51.968-41.899
			c-14.477-14.371-27.483-30.151-38.827-47.104c-12.408-18.242-22.229-38.114-29.184-59.051
			c-7.973-24.596-1.366-51.585,17.067-69.717l15.189-15.189c4.223-4.242,11.085-4.257,15.326-0.034
			c0.011,0.011,0.023,0.022,0.034,0.034l47.957,47.957c4.242,4.223,4.257,11.085,0.034,15.326c-0.011,0.011-0.022,0.022-0.034,0.034
			l-28.16,28.16c-8.08,7.992-9.096,20.692-2.389,29.867c10.185,13.978,21.456,27.131,33.707,39.339
			c13.659,13.718,28.508,26.197,44.373,37.291c9.167,6.394,21.595,5.316,29.525-2.56l27.221-27.648
			c4.223-4.242,11.085-4.257,15.326-0.034c0.011,0.011,0.022,0.022,0.034,0.034l48.043,48.128
			C391.911,345.502,391.926,352.363,387.703,356.605z"/>
	</g>
</g>
</svg>
                                           </div>
                                       </a>
                                       }

                                       {apiData.EmailID &&
                                       <a href={`mailto:${apiData.EmailID}`}>
                                          <div class="s_icon">
                                              

                                          <svg  viewBox="0 0 448 448" className="email-color"><path d="m314.375 144h-180.75l90.375 77.464844zm0 0"/><path d="m224 240c-1.910156 0-3.757812-.683594-5.207031-1.929688l-98.792969-84.679687v150.609375h208v-150.609375l-98.792969 84.679687c-1.449219 1.246094-3.296875 1.929688-5.207031 1.929688zm0 0"/><path d="m224 0c-123.710938 0-224 100.289062-224 224s100.289062 224 224 224 224-100.289062 224-224c-.140625-123.652344-100.347656-223.859375-224-224zm120 312c0 4.417969-3.582031 8-8 8h-224c-4.417969 0-8-3.582031-8-8v-176c0-4.417969 3.582031-8 8-8h224c4.417969 0 8 3.582031 8 8zm0 0"/></svg>
                                              </div>  
                                       </a>

                                       }
                                       
                                      
                                       
                                       
                                    {apiData.Whatsapp &&           <a href={`https://api.whatsapp.com/send/?phone=${defaultapidata.MobileNo}&text=Hi&app_absent=0`}>
                                            <div class="s_icon">
                                                <svg version="1.1" id="Capa_1" viewBox="0 0 512 512"style={{enableBackground:"new 0 0 512 512"}} xmlSpace="preserve">
                                                    <path style={{fill:"#4CAF50"}} d="M256.064,0h-0.128l0,0C114.784,0,0,114.816,0,256c0,56,18.048,107.904,48.736,150.048l-31.904,95.104
	l98.4-31.456C155.712,496.512,204,512,256.064,512C397.216,512,512,397.152,512,256S397.216,0,256.064,0z" />
                                                    <path style={{fill:"#FAFAFA"}} d="M405.024,361.504c-6.176,17.44-30.688,31.904-50.24,36.128c-13.376,2.848-30.848,5.12-89.664-19.264
	C189.888,347.2,141.44,270.752,137.664,265.792c-3.616-4.96-30.4-40.48-30.4-77.216s18.656-54.624,26.176-62.304
	c6.176-6.304,16.384-9.184,26.176-9.184c3.168,0,6.016,0.16,8.576,0.288c7.52,0.32,11.296,0.768,16.256,12.64
	c6.176,14.88,21.216,51.616,23.008,55.392c1.824,3.776,3.648,8.896,1.088,13.856c-2.4,5.12-4.512,7.392-8.288,11.744
	c-3.776,4.352-7.36,7.68-11.136,12.352c-3.456,4.064-7.36,8.416-3.008,15.936c4.352,7.36,19.392,31.904,41.536,51.616
	c28.576,25.44,51.744,33.568,60.032,37.024c6.176,2.56,13.536,1.952,18.048-2.848c5.728-6.176,12.8-16.416,20-26.496
	c5.12-7.232,11.584-8.128,18.368-5.568c6.912,2.4,43.488,20.48,51.008,24.224c7.52,3.776,12.48,5.568,14.304,8.736
                               C411.2,329.152,411.2,344.032,405.024,361.504z" />
                                                </svg>
                                            </div>
                                        </a>


}



                                       
                                       
{apiData.Linkedin &&         <a href="https://www.linkedin.com/in/benjamin-s-64322a1bb/">
                                            <div class="s_icon">
                                                <svg version="1.1" id="Capa_1"  
	 viewBox="0 0 112.196 112.196" style={{enableBackground:"new 0 0 112.196 112.196"}}>
<g>
	<circle style={{fill:"#007AB9"}} cx="56.098" cy="56.097" r="56.098"/>
	<g>
		<path style={{fill:"#F1F2F2"}} d="M89.616,60.611v23.128H76.207V62.161c0-5.418-1.936-9.118-6.791-9.118
			c-3.705,0-5.906,2.491-6.878,4.903c-0.353,0.862-0.444,2.059-0.444,3.268v22.524H48.684c0,0,0.18-36.546,0-40.329h13.411v5.715
			c-0.027,0.045-0.065,0.089-0.089,0.132h0.089v-0.132c1.782-2.742,4.96-6.662,12.085-6.662
			C83.002,42.462,89.616,48.226,89.616,60.611L89.616,60.611z M34.656,23.969c-4.587,0-7.588,3.011-7.588,6.967
			c0,3.872,2.914,6.97,7.412,6.97h0.087c4.677,0,7.585-3.098,7.585-6.97C42.063,26.98,39.244,23.969,34.656,23.969L34.656,23.969z
			 M27.865,83.739H41.27V43.409H27.865V83.739z"/>
	</g>
</g>
                                                </svg>
                                            </div>
                                        </a>
                                 



}


<a href="" onClick={vcfDownload}>
    <div class="s_icon">
    <svg  viewBox="0 0 952 957" className="branding-color-add">
    <g id="Artboard" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <circle id="Oval" fill="#000000" cx="473.5" cy="478.5" r="454.5"></circle>
        <g id="new-user" transform="translate(203.000000, 172.000000)" fill="#FFFFFF" fill-rule="nonzero">
            <path d="M233.272,310.198 C319.449,310.193 350.456,223.907 358.573,153.029 C368.572,65.715 327.282,0 233.272,0 C139.274,0 97.963,65.71 107.97,153.029 C116.095,223.907 147.093,310.204 233.272,310.198 Z" id="Path"></path>
            <path d="M421.707,346.115 C424.48,346.115 427.235,346.198 429.971,346.35 C425.87,340.5 421.123,335.34 415.568,331.192 C399.009,318.833 377.563,314.778 358.604,307.328 C349.375,303.703 341.111,300.102 333.353,296.002 C307.169,324.717 273.024,339.738 233.262,339.742 C193.513,339.742 159.371,324.721 133.19,296.002 C125.432,300.103 117.166,303.703 107.939,307.328 C88.98,314.779 67.535,318.833 50.975,331.192 C22.337,352.567 14.936,400.652 9.121,433.452 C4.322,460.528 1.098,488.159 0.157,515.661 C-0.572,536.964 9.946,539.951 27.768,546.382 C50.083,554.43 73.124,560.405 96.32,565.303 C141.117,574.763 187.293,582.032 233.27,582.357 C255.548,582.198 277.871,580.401 300.062,577.524 C283.631,553.717 273.994,524.879 273.994,493.829 C273.995,412.378 340.258,346.115 421.707,346.115 Z" id="Path"></path>
            <path d="M421.707,375.658 C356.445,375.658 303.536,428.567 303.536,493.829 C303.536,559.091 356.444,612 421.707,612 C486.97,612 539.879,559.091 539.879,493.829 C539.879,428.566 486.969,375.658 421.707,375.658 Z M473.407,514.103 L441.982,514.103 L441.982,545.527 C441.982,556.725 432.905,565.803 421.708,565.803 C410.51,565.803 401.432,556.725 401.432,545.527 L401.432,514.103 L370.008,514.103 C358.81,514.103 349.732,505.026 349.732,493.827 C349.732,482.629 358.809,473.551 370.008,473.551 L401.432,473.551 L401.432,442.127 C401.432,430.929 410.51,421.851 421.708,421.851 C432.906,421.851 441.982,430.929 441.982,442.127 L441.982,473.551 L473.407,473.551 C484.605,473.551 493.683,482.629 493.683,493.827 C493.683,505.027 484.606,514.103 473.407,514.103 Z" id="Shape"></path>
        </g>
    </g>
</svg>
    </div>
</a>





      

{/* {apiData.Instagram   &&                <a href="https://www.instagram.com/writusofficial/">
                                            <div class="s_icon">

                                                <svg version="1.1" id="Layer_1" viewBox="0 0 551.034 551.034" style={{enableBackground:"new 0 0 551.034 551.034"}} xmlSpace="preserve">
                                                    <g>

                                                        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="275.517" y1="4.57" x2="275.517" y2="549.72" gradientTransform="matrix(1 0 0 -1 0 554)">
                                                            <stop offset="0" style={{stopColor:"#E09B3D"}} />
                                                            <stop offset="0.3" style={{stopColor:"#C74C4D"}} />
                                                            <stop offset="0.6"style={{stopColor:"#C21975"}} />
                                                            <stop offset="1" style={{stopColor:"#7024C4" }} />
                                                        </linearGradient>
                                                        <path style={{fill:"url(#SVGID_1_)"}} d="M386.878,0H164.156C73.64,0,0,73.64,0,164.156v222.722
		c0,90.516,73.64,164.156,164.156,164.156h222.722c90.516,0,164.156-73.64,164.156-164.156V164.156
		C551.033,73.64,477.393,0,386.878,0z M495.6,386.878c0,60.045-48.677,108.722-108.722,108.722H164.156
		c-60.045,0-108.722-48.677-108.722-108.722V164.156c0-60.046,48.677-108.722,108.722-108.722h222.722
		c60.045,0,108.722,48.676,108.722,108.722L495.6,386.878L495.6,386.878z" />

                                                        <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="275.517" y1="4.57" x2="275.517" y2="549.72" gradientTransform="matrix(1 0 0 -1 0 554)">
                                                            <stop offset="0" style={{stopColor:"#E09B3D"}} />
                                                            <stop offset="0.3"style={{stopColor:"#C74C4D" }}/>
                                                            <stop offset="0.6" style={{stopColor:"#C21975"}} />
                                                            <stop offset="1" style={{stopColor:"#7024C4"}} />
                                                        </linearGradient>
                                                        <path style={{fill:"url(#SVGID_2_)"}} d="M275.517,133C196.933,133,133,196.933,133,275.516s63.933,142.517,142.517,142.517
		S418.034,354.1,418.034,275.516S354.101,133,275.517,133z M275.517,362.6c-48.095,0-87.083-38.988-87.083-87.083
		s38.989-87.083,87.083-87.083c48.095,0,87.083,38.988,87.083,87.083C362.6,323.611,323.611,362.6,275.517,362.6z" />

                                                        <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="418.31" y1="4.57" x2="418.31" y2="549.72" gradientTransform="matrix(1 0 0 -1 0 554)">
                                                            <stop offset="0" style={{stopColor:"#E09B3D"}} />
                                                            <stop offset="0.3" style={{stopColor:"#C74C4D"}} />
                                                            <stop offset="0.6" style={{stopColor:"#C21975"}} />
                                                            <stop offset="1" style={{stopColor:"#7024C4"}} />
                                                        </linearGradient>
                                                        <circle style={{fill:"url(#SVGID_3_)"}} cx="418.31" cy="134.07" r="34.15" />
                                                    </g>
                                                </svg>
                                            </div>
                                        </a>




} */}




                                    </div>
                                    <div class="contact-details">
                                        <div class="phone">
                                            
                                            <span><a href={`tel:${apiData.MobileNo}`}>{apiData.MobileNo}</a></span>
                                        </div>


                                        <div class="phone">
                                           
                                            <span><a href={`mailto:${apiData.EmailID}`}>{apiData.EmailID}</a></span>
                                        </div>



{apiData.Landline ? (

                                        <div class="phone">
                                          
                                            <span><a href={`tel:${apiData.Landline}`}>{apiData.Landline}</a></span>
                                        </div>
):""}
                                        <div class="phone"  >
                                            
                                            <span onClick={vcfDownload}><a href="">{content.download}</a></span>
                                        </div>

                                    </div>
                                    <div class="cta-button" style={{display:first}}>
                                    <button class="btn btn-primary" id="contact" onClick={handleShow}>{content.send}</button>
                                </div>
                                </div>



                                <div class="second-section-load second" style={{display:second}}>
                                    <div class="new-header">
                                        <div class="custom_topic">
                                            <h3>Send your contact to {apiData.Name} </h3>
                                        </div>
                                        <span  onClick={handleClose} class="c_icon close_icon" id="close"><svg id="Capa_1" enable-background="new 0 0 413.348 413.348" height="512" viewBox="0 0 413.348 413.348" width="512" xmlns="http://www.w3.org/2000/svg">
                                                <path d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z" />
                                            </svg></span>
                                    </div>
                                    <div class="contact-form">

                                    { mailmsg !== "success" ? ( <Formik 
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { validate }) => {
                onsubmit();
             }}
            >
          <Form className="custom_form" ref={form} >
                <div className=" share_contact">
                <label htmlFor="name"> <span class="svg-icon-form">

<svg id="Layer_1" enable-background="new 0 0 480.063 480.063" height="512" viewBox="0 0 480.063 480.063" width="512"><path d="m402.032 424.806v47.257c0 4.418-3.582 8-8 8s-8-3.582-8-8v-47.257c0-36.795-29.775-66.572-66.573-66.571-17.411 0-33.208-8.87-42.259-23.728-2.298-3.773-1.103-8.696 2.671-10.994 3.773-2.299 8.695-1.103 10.994 2.671 6.122 10.051 16.811 16.051 28.594 16.051 45.637-.002 82.573 36.93 82.573 82.571zm-139.606-80.193c.941 4.317-1.796 8.579-6.113 9.52-21.054 4.587-42.467-.005-59.516-11.642-16.878 18.087-39.176 15.744-36.191 15.744-36.795-.001-66.573 29.773-66.573 66.571v47.257c0 4.418-3.582 8-8 8s-8-3.582-8-8v-47.257c0-45.636 36.929-82.571 82.571-82.571 18.462 0 33.429-14.875 33.429-33.342v-2.107c-34.919-16.697-59.429-51.784-60.923-92.643-14.37-3.455-25.077-16.317-25.077-31.62v-41.473c-.437-20.3 2.577-71.143 39.648-106.877 45.775-44.126 119.183-41.323 173.161-15.338 5.261 2.535 6.06 9.643 1.691 13.324 27.345 6.67 50.925 23.48 66.074 47.538.782 1.239 2.214 3.184 1.84 6.287-.232 1.931-.807 3.565-2.295 5.075-9.75 9.888-15.119 22.991-15.119 36.896v54.57c0 4.418-3.582 8-8 8s-8-3.582-8-8v-54.57c0-16.037 5.479-31.259 15.542-43.487-15.338-21.936-39.268-36.044-66.332-38.942l-14.061-1.506c-8.222-.88-9.835-12.207-2.194-15.352l6.395-2.633c-83.286-29.035-172.351 3.226-172.351 114.928v41.56c0 6.348 3.656 11.865 9 14.636v-51.863c0-30.878 25.122-56 56-56h102c30.878 0 56 25.12 56 55.997v65.503c0 69.574-67.988 122.42-137.17 102.053-.45 5.708-1.871 11.216-4.186 16.336 13.458 9.242 30.453 12.97 47.23 9.314 4.317-.94 8.579 1.797 9.52 6.114zm-22.394-43.425c50.178 0 91-40.822 91-91v-64.895c0-22.054-17.944-39.997-40-39.997h-102c-22.056 0-40 17.944-40 40v64.892c0 50.178 40.822 91 91 91zm81 137.875h-24c-4.418 0-8 3.582-8 8s3.582 8 8 8h24c4.418 0 8-3.582 8-8s-3.582-8-8-8z"/></svg></span></label>
                
                <Field 
                    type="text" 
                    id="text" 
                    name="name" 
                    placeholder="Enter your Name"
                />
                <ErrorMessage name="name" component={TextError}/>
                </div>
                <div className=" share_contact">
                <label htmlFor="email"><span class="svg-icon-form">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <g id="Mail">
                                                        <path d="M496,104H16a8,8,0,0,0-8,8V400a8,8,0,0,0,8,8H496a8,8,0,0,0,8-8V112A8,8,0,0,0,496,104ZM157.307,246.114l94.577,56.746a8,8,0,0,0,8.232,0l94.778-56.867L479,392H33.3ZM24,378.235V166.13l119.4,71.637ZM368.805,237.646,488,166.13V377.879ZM488,120v27.47L256,286.67,24,147.47V120Z" />
                                                        <path d="M40,144H88a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Z" />
                                                        <path d="M112,144h8a8,8,0,0,0,0-16h-8a8,8,0,0,0,0,16Z" />
                                                    </g>
                                                </svg></span></label>
                <Field 
                    type="email" 
                    id="email" 
                    name="email" 
                    placeholder="Enter your Email"
                />
                <ErrorMessage name="email" component={TextError}/>
                </div>
                <div className=" share_contact">
                <label htmlFor="phone_number"><span class="svg-icon-form">
<svg viewBox="0 0 512 512">
                                                    <g id="Smartphone">
                                                        <path d="m352 8h-192a40.045 40.045 0 0 0 -40 40v416a40.045 40.045 0 0 0 40 40h192a40.045 40.045 0 0 0 40-40v-416a40.045 40.045 0 0 0 -40-40zm-41.758 16-4.8 24h-98.883l-4.8-24zm65.758 440a24.027 24.027 0 0 1 -24 24h-192a24.027 24.027 0 0 1 -24-24v-416a24.027 24.027 0 0 1 24-24h25.441l6.714 33.569a8 8 0 0 0 7.845 6.431h112a8 8 0 0 0 7.845-6.431l6.714-33.569h25.441a24.027 24.027 0 0 1 24 24z" />
                                                        <path d="m208 456h-48a8 8 0 0 0 0 16h48a8 8 0 0 0 0-16z" />
                                                        <path d="m240 456h-8a8 8 0 0 0 0 16h8a8 8 0 0 0 0-16z" />
                                                    </g>
                                                </svg></span></label>
                <Field 
                    type="text" 
                    id="text" 
                    name="phone_number" 
                    placeholder="05XXXXXXXX"
                />
                  <ErrorMessage name="phone_number" component={TextError}/>
                </div>
                {/* <Button variant="primary" 
   
   type="submit"
    >Send</Button> */}

<div class="cta-button">
                                           <button class="btn btn-primary" type="submit">{content.submit}</button>
                                        </div>
             
            
            </Form>
        </Formik>) : ( <div><h4>Mail Sent successfully</h4></div>)}

                                        {/* <form  class="custom_form" ref={form} onSubmit={onSubmit}>
                                            <div class=" share_contact">
                                                <label for="name">
                                                    <span class="svg-icon-form">

<svg id="Layer_1" enable-background="new 0 0 480.063 480.063" height="512" viewBox="0 0 480.063 480.063" width="512"><path d="m402.032 424.806v47.257c0 4.418-3.582 8-8 8s-8-3.582-8-8v-47.257c0-36.795-29.775-66.572-66.573-66.571-17.411 0-33.208-8.87-42.259-23.728-2.298-3.773-1.103-8.696 2.671-10.994 3.773-2.299 8.695-1.103 10.994 2.671 6.122 10.051 16.811 16.051 28.594 16.051 45.637-.002 82.573 36.93 82.573 82.571zm-139.606-80.193c.941 4.317-1.796 8.579-6.113 9.52-21.054 4.587-42.467-.005-59.516-11.642-16.878 18.087-39.176 15.744-36.191 15.744-36.795-.001-66.573 29.773-66.573 66.571v47.257c0 4.418-3.582 8-8 8s-8-3.582-8-8v-47.257c0-45.636 36.929-82.571 82.571-82.571 18.462 0 33.429-14.875 33.429-33.342v-2.107c-34.919-16.697-59.429-51.784-60.923-92.643-14.37-3.455-25.077-16.317-25.077-31.62v-41.473c-.437-20.3 2.577-71.143 39.648-106.877 45.775-44.126 119.183-41.323 173.161-15.338 5.261 2.535 6.06 9.643 1.691 13.324 27.345 6.67 50.925 23.48 66.074 47.538.782 1.239 2.214 3.184 1.84 6.287-.232 1.931-.807 3.565-2.295 5.075-9.75 9.888-15.119 22.991-15.119 36.896v54.57c0 4.418-3.582 8-8 8s-8-3.582-8-8v-54.57c0-16.037 5.479-31.259 15.542-43.487-15.338-21.936-39.268-36.044-66.332-38.942l-14.061-1.506c-8.222-.88-9.835-12.207-2.194-15.352l6.395-2.633c-83.286-29.035-172.351 3.226-172.351 114.928v41.56c0 6.348 3.656 11.865 9 14.636v-51.863c0-30.878 25.122-56 56-56h102c30.878 0 56 25.12 56 55.997v65.503c0 69.574-67.988 122.42-137.17 102.053-.45 5.708-1.871 11.216-4.186 16.336 13.458 9.242 30.453 12.97 47.23 9.314 4.317-.94 8.579 1.797 9.52 6.114zm-22.394-43.425c50.178 0 91-40.822 91-91v-64.895c0-22.054-17.944-39.997-40-39.997h-102c-22.056 0-40 17.944-40 40v64.892c0 50.178 40.822 91 91 91zm81 137.875h-24c-4.418 0-8 3.582-8 8s3.582 8 8 8h24c4.418 0 8-3.582 8-8s-3.582-8-8-8z"/></svg></span></label>
                                                <input name="name" type="text" id="text" value="" placeholder="Enter your Name"/></div>
                                            <div class=" share_contact"><label for="email"><span class="svg-icon-form">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                    <g id="Mail">
                                                        <path d="M496,104H16a8,8,0,0,0-8,8V400a8,8,0,0,0,8,8H496a8,8,0,0,0,8-8V112A8,8,0,0,0,496,104ZM157.307,246.114l94.577,56.746a8,8,0,0,0,8.232,0l94.778-56.867L479,392H33.3ZM24,378.235V166.13l119.4,71.637ZM368.805,237.646,488,166.13V377.879ZM488,120v27.47L256,286.67,24,147.47V120Z" />
                                                        <path d="M40,144H88a8,8,0,0,0,0-16H40a8,8,0,0,0,0,16Z" />
                                                        <path d="M112,144h8a8,8,0,0,0,0-16h-8a8,8,0,0,0,0,16Z" />
                                                    </g>
                                                </svg></span></label>
                                                <input name="email" type="email" id="email" value="" placeholder="abc@xxxx.xxx"/></div>
                                            <div class=" share_contact"><label for="phone_number"><span class="svg-icon-form">
<svg viewBox="0 0 512 512">
                                                    <g id="Smartphone">
                                                        <path d="m352 8h-192a40.045 40.045 0 0 0 -40 40v416a40.045 40.045 0 0 0 40 40h192a40.045 40.045 0 0 0 40-40v-416a40.045 40.045 0 0 0 -40-40zm-41.758 16-4.8 24h-98.883l-4.8-24zm65.758 440a24.027 24.027 0 0 1 -24 24h-192a24.027 24.027 0 0 1 -24-24v-416a24.027 24.027 0 0 1 24-24h25.441l6.714 33.569a8 8 0 0 0 7.845 6.431h112a8 8 0 0 0 7.845-6.431l6.714-33.569h25.441a24.027 24.027 0 0 1 24 24z" />
                                                        <path d="m208 456h-48a8 8 0 0 0 0 16h48a8 8 0 0 0 0-16z" />
                                                        <path d="m240 456h-8a8 8 0 0 0 0 16h8a8 8 0 0 0 0-16z" />
                                                    </g>
                                                </svg></span></label>
                                                <input name="phone_number" type="text" id="text" value="" placeholder="05XXXXXXXX"/></div>
                                            <div class="cta-button">
                                           <button class="btn btn-primary" type="submit">Submit</button>
                                        </div>
                                        </form> */}
                                    </div>
                                </div>




                               




                            </div>
                        </div>
                    </div>


                </div>



            </div>
        </div>







        {/* <div class="container">
            <div class="col-12">

                <div class="row mobile-row">
                    <div class="col-lg-6 no-padding col-center col-xs-12">
                    <div class="card bg-white custom-card">
                        <img src="https://picsum.photos/400/300"/>
                        <div class="p-f-20">
                        <div class="name-fields">
                            <h1>
                                {apiData.Name ? apiData.Name : ""} &nbsp;
                                <FontAwesomeIcon icon={faAddressCard} color="black"  size="xs"   color="#4d85c5" className="vcfdown" onClick={vcfDownload} />
                            
                            </h1>
                           
                           
                            <h3>{apiData.designation}</h3>
                            <h3> {apiData.company}</h3>
                        </div>
                            <div class="content-text">
                            <p>You can reach me! &nbsp;
                           <a href={`https://api.whatsapp.com/send/?phone=${apiData.MobileNo}&text=Hi&app_absent=0`} ><FontAwesomeIcon icon={faWhatsapp} color="black"  size="2x"   color="white" className="whtass" /></a>
                            

                           
                            </p>
                            </div>
                            <div class="contact-details">
                            <div class="phone">
                               <div class="svg-icon">
                                <svg version="1.1" id="Layer1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 430 430" style={{enableBackground: "new 0 0 430 430"}} xmlSpace="preserve">

<path class="st0" d="M224.3,315.8h-106c-16,0-29.1-13-29.1-29.1V64.6c0-16,13-29.1,29.1-29.1h106c16,0,29.1,13,29.1,29.1V189
	c0,2-1,3.8-2.6,5c-1.6,1.1-3.7,1.4-5.6,0.6L210.1,181c-0.5-0.1-1.7-0.4-4.6-0.7c-0.2,0-0.4,0-0.5-0.1c-6.4,0.2-12,4.3-14,10.4
	c-2.7,8,1.6,16.6,9.6,19.3l48.8,16.8c2.4,0.8,4,3.1,4,5.7v54.4C253.4,302.8,240.3,315.8,224.3,315.8z M118.3,47.5
	c-9.4,0-17.1,7.7-17.1,17.1v222.1c0,9.4,7.7,17.1,17.1,17.1h106c9.4,0,17.1-7.7,17.1-17.1v-50.1l-44.8-15.4
	c-14.2-4.8-21.9-20.3-17.1-34.5c3.7-11.1,14.1-18.5,25.9-18.5c0.4,0,0.9,0,1.3,0.1c0,0,0.1,0,0.1,0c2.5,0.3,5.3,0.6,7.3,1.3
	c0.1,0,0.2,0.1,0.2,0.1l27,10.5V64.6c0-9.4-7.7-17.1-17.1-17.1H118.3z"/>
<path class="st1" d="M190.6,74.6h-38.7c-3.3,0-6-2.7-6-6s2.7-6,6-6h38.7c3.3,0,6,2.7,6,6S193.9,74.6,190.6,74.6z"/>
<path class="st0" d="M228.4,367.5c-1.5,0-2.9-0.5-4.1-1.6l-55.9-51.7c-1.8-1.7-2.4-4.3-1.5-6.6c0.9-2.3,3.1-3.8,5.6-3.8h51.8
	c9.4,0,17.1-7.7,17.1-17.1v-50.1l-44.8-15.4c-14.2-4.8-21.9-20.3-17.1-34.5c3.7-11.1,14.1-18.5,25.9-18.5c0.4,0,0.9,0,1.3,0.1
	c0,0,0.1,0,0.1,0c2.5,0.3,5.3,0.6,7.3,1.3c0.1,0,0.2,0.1,0.2,0.1l35.1,13.7l32.4,11.3c0.3,0.1,0.7,0.3,1,0.4
	c6.5,3.5,11.4,9.5,13.4,16.6l15.5,55.3l10.8,33.4c0.8,2.5-0.1,5.3-2.4,6.8c0,0-59,39.5-88.5,59.2
	C230.7,367.2,229.5,367.5,228.4,367.5z M187.8,315.8l41.2,38.1c25.7-17.2,68-45.5,80.8-54l-9.4-29.2c0-0.1,0-0.1-0.1-0.2L284.7,215
	c-1.1-3.9-3.7-7.2-7.3-9.2l-32-11.2c-0.1,0-0.1,0-0.2-0.1L210.1,181c-0.5-0.1-1.7-0.4-4.6-0.7c-0.2,0-0.4,0-0.5-0.1
	c-6.4,0.2-12,4.3-14,10.4c-2.7,8,1.6,16.6,9.6,19.3l48.8,16.8c2.4,0.8,4,3.1,4,5.7v54.4c0,16-13,29.1-29.1,29.1H187.8z"/>
<path class="st0" d="M246.4,394.5c-0.4,0-0.8,0-1.2-0.1c-1.6-0.3-2.9-1.2-3.8-2.5l-18-26.9c-1.8-2.8-1.1-6.5,1.6-8.3
	c2.8-1.8,6.5-1.1,8.3,1.6l14.7,21.9l78.5-52.5l-14.7-21.9c-1.8-2.8-1.1-6.5,1.6-8.3c2.8-1.8,6.5-1.1,8.3,1.6l18,26.9
	c1.8,2.8,1.1,6.5-1.6,8.3l-88.4,59.2C248.7,394.1,247.6,394.5,246.4,394.5z"/>
</svg>
                                </div>
                                <span><a href="#">{apiData.MobileNo}</a></span>
                                </div>
                                
                                 <div class="phone">
                               <div class="svg-icon">
                                <svg version="1.1" id="Layer1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 430 430" style={{enableBackground: "new 0 0 430 430"}} xmlSpace="preserve">

<g>
	<path class="st1" d="M278.93,352.16c-8.66,0-18.12-1.53-28.12-4.62c-23.14-7.16-46.66-21.87-66.23-41.44l-60.81-60.81
		c-19.57-19.57-34.32-43.12-41.54-66.32c-7.52-24.16-6.02-45.28,4.23-59.47c8.16-11.29,16.67-21.56,23.96-28.9
		c11.21-11.28,16.41-12.73,19.7-12.74c0,0,0.01,0,0.01,0c2.74,0,5.21,1.23,6.76,3.37l37.29,51.34c5.61,7.72-1.46,20.86-10.41,29.81
		c-6.77,6.77-7.66,13.33-6.72,14.27l95.82,95.82c1.67,0.08,7.21-1.81,13.2-7.79c8.57-8.57,21.33-16.57,29.07-10.92l54.21,39.53
		c1.98,1.44,3.12,3.72,3.12,6.24c0.01,3.23-1.54,8.04-13.09,19.22c-7.51,7.27-17.93,15.89-29.33,24.28
		C301.8,349.09,291.17,352.16,278.93,352.16z M128.77,91c-4.78,2.99-16.38,13.63-32.33,35.71c-16.78,23.23-0.28,73.54,36.04,109.86
		l60.81,60.81c36.3,36.3,86.43,52.66,109.46,35.72c20.13-14.8,32.37-26.73,36.24-32.12l-50.38-36.74
		c-2.12,0.32-7.76,3.09-13.83,9.15c-4.57,4.57-9.77,8.1-14.65,9.94c-8.74,3.29-13.54,0.28-15.63-1.81l-96.17-96.17
		c-7.12-7.12-4.23-20.74,6.72-31.69c6.45-6.45,8.79-12.45,8.83-14.3L128.77,91z M164.21,139.8L164.21,139.8L164.21,139.8z"/>
</g>
</svg>
                                </div>
                                <span><a href="#">{apiData.Landline}</a></span>
                                </div>
                                 <div class="phone">
                               <div class="svg-icon">
                               <svg version="1.1" id="Layer1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 430 430" style={{enableBackground: "new 0 0 430 430"}} xmlSpace="preserve">

<g>
	<path class="st0" d="M214.87,260.53C214.87,260.53,214.87,260.53,214.87,260.53c-11.61,0-22.52-4.53-30.72-12.74L53.48,116.87
		c-2.53-2.53-2.52-6.63,0.01-9.16c2.53-2.53,6.63-2.52,9.16,0.01l130.67,130.92c5.75,5.76,13.41,8.94,21.56,8.94h0
		c8.15,0,15.8-3.17,21.55-8.94l130.65-130.88c2.52-2.53,6.63-2.53,9.16-0.01c2.53,2.53,2.54,6.63,0.01,9.16L245.59,247.79
		C237.39,256,226.48,260.53,214.87,260.53z"/>
	<path class="st0" d="M374.17,326.9H55.83c-3.58,0-6.47-2.9-6.47-6.48V109.58c0-1.72,0.68-3.36,1.9-4.58
		c1.21-1.21,2.86-1.9,4.58-1.9c0,0,0,0,0,0l318.34,0.03c3.58,0,6.47,2.9,6.47,6.48v210.81C380.65,324,377.75,326.9,374.17,326.9z
		 M62.3,313.95H367.7V116.08L62.3,116.06V313.95z"/>
	<path class="st0" d="M369.34,301.72c-1.51,0-3.02-0.52-4.25-1.59l-78.66-68.47c-5.61-4.88-11.85-9.16-18.53-12.72
		c-3.16-1.68-4.35-5.6-2.67-8.76c1.68-3.16,5.6-4.36,8.76-2.67c7.55,4.02,14.6,8.86,20.95,14.38l78.66,68.47
		c2.7,2.35,2.98,6.44,0.63,9.14C372.95,300.96,371.15,301.72,369.34,301.72z M62.25,300.39c-1.81,0-3.61-0.76-4.9-2.23
		c-2.34-2.7-2.05-6.79,0.65-9.13l77.61-67.29c6.14-5.33,12.98-10.04,20.32-14c3.15-1.7,7.07-0.52,8.77,2.63
		c1.7,3.15,0.52,7.08-2.63,8.77c-6.5,3.51-12.55,7.67-17.98,12.38L66.49,298.8C65.26,299.86,63.75,300.39,62.25,300.39z"/>
</g>
</svg>
                                </div>
                                <span><a href="#">{apiData.EmailID}</a></span>
                                </div>
                               
                            </div>
                            
                            <div class="cta-button">
                           <button class="btn btn-primary" onClick={handleShow}>Send My Contact</button>
                            </div>
                            </div>
                        </div>
                    </div>                    
                   
            </div>
        
        
        
        </div>
    </div> */}
    
  </div>
    
    )
}

export default Employee
